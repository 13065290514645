var CONTACT = {
	checkForResponse: function(){
		$('.wpcf7-textarea').autogrow();
	}
}

$(document).ready(function(){
		CONTACT.checkForResponse();
		$(document).on("click", "#contact-us-close", function(e){
			e.preventDefault();
				$('#advancedSearchModal').addClass('hidden').fadeOut('slow');
		});
});