var homepage = {
	initializeSlider: function () {

	var animateSvg = function(){
			//animate after slide change
			$('#slider-svg').animate({
				'background-position-x':'-50%'
			}, 8100, 'linear', function(){
				$('#slider-svg').css({
					'background-position-x':'50%'
				});
			});
		}

		// animate only once on page load
		var animateSvgOnce = function(){
			var flag = true;
			if (flag) {
					$('#slider-svg').animate({
						'background-position-x':'-50%'
					}, 8100, 'linear', function(){
						$('#slider-svg').css({
							'background-position-x':'50%'
						});
						return flag = false;
					});
			}
		}


		if ( $("body").hasClass("page-template-homepage") ){
			// start Slick slider
			$('.slider-container').slick({
				slide: '.slide',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 8000,
				arrows: true,
				fade: true,
				pauseOnHover: true,
				pauseOnFocus: true,
				infinite: true,
				prevArrow: $('.prev-slider-home'),
				nextArrow: $('.next-slider-home')
			});

			animateSvgOnce();

			$('.slider-container').on('afterChange', function(event, slick, currentSlide, nextSlide){
				animateSvg();
			});

		}
	}
}


$(document).ready(function(){
	if( $('#slider-svg').length ){
		homepage.initializeSlider();
	}
  $('.case-study-carousel').slick({
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });
});


